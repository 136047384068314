@import "~@angular/material/prebuilt-themes/indigo-pink.css";


/* VARIABLES DE COLORES GENERALES */

:root{
  --roig: #7B241C;
  --gris-oscuro: #6B6D6E;
  --gris-claro: #f9f9f9;
  --blau: #5EB9DE;
  --verd: #67C963;
  --taronja: #FFDE56;
  amarillo:#FFDE56
}

.titulo-cabecera {
  text-align: center;
  margin-top: 2%;
}

.alumno-general {
  font-size: 0.8rem;
}


/* FIN VARIABLES DE COLORES GENERALES */
.row{
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 15px;
}

.hidden {
  display: none;
}

.view {
  display: block;
}

.trash {
  color: indianred;
}

.header {
  font: bold !important;
  font-size: 16px !important;
  background-color: rgb(176, 245, 245);
}

.img-profile {
  width: 200px;
  height: 220px;
}

.card .card-header .view {
  background-color: #009DA0 !important;
  margin-bottom: 5px;
  padding: 10px !important;
  color: #ffffff !important;
  height: 100%;
}

.asignatura-style {
  align-content: center;
  margin: 0 auto;
}

.asignatura-style li {
  min-width: 25% !important;
  text-align: center;
}

.asignatura-style-header li {
  min-width: 25%;
  background-color: #009DA0 !important;
  text-align: center;
  color: white;
}

.asignatura-style-header li:first-child {
  min-width: 40%;
}

.asignatura-style li:nth-child(1) {
  min-width: 40% !important;
}

.asignatura-style li:nth-child(2) {
  min-width: 40% !important;
}

.asignatura-style input {
  width: 60%;
  text-align: center;
}

.edit-right {
  float: right;
  margin-right: 5px;
  margin-top: 7px;
}

.selectedRow{
  background-color: rgba(28, 157, 160, 0.2) !important;
}

/*CSS PARA BLOQUE HORARIOS (MENÚ)*/
section#user-profile .profile-with-cover {
  z-index: 0;
}

app-comercial .mat-tab-header {
  background-color: aliceblue !important;
}

.mat-chip-list-wrapper{
  margin-top: 10px !important;
  justify-content: center !important;
  margin-bottom: 10px !important;
}





